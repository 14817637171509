<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      class="elevation-1 "
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 100],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
    >
      <template slot="no-data">
        {{ $t('table.messages.no_data') }}
      </template>
      <template
        v-slot:footer.page-text=""
        class="mr-0"
      >
        {{ $t('table.messages.page') }}
        {{ options.page }}
      </template>
      <template v-slot:top>
        <v-container class="pt-5">
          <v-row class="d-flex justify-end">
            <v-col cols="4">
              <div class="align-self-center">
                <v-text-field
                  id="search"
                  v-model="search"
                  name="search pa-0"
                  :placeholder="$t('placeholder.schoolNamePlaceholder')"
                  outlined
                />
              </div>
            </v-col>
            <v-col cols="2">
              <div class="mb-9">
                <v-btn
                  large
                  color="primary"
                  @click="onSearch"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.search') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="4">
              <v-select
                v-model="filterSearch"
                :items="jobOptions"
                :label="$t('messages.representManage')"
                item-text="text"
                item-value="value"
                @change="selectEvent"
              />
            </v-col>
            <v-col
              offset="4"
              cols="2"
            >
              <v-dialog
                v-model="dialog"
                max-width="687px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="forth"
                    class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-end"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="font-weight-bold text-h5">
                      {{ $t('messages.addNewItem') }}</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            outlined
                            :label="$t('table.headers.college_name')"
                            :rules="editedIndex === -1 ? rules: []"
                            validate-on-blur
                          >
                            <template v-slot:label>
                              <div>
                                <span>{{ $t('table.headers.college_name') }}</span>
                                <span
                                  v-if="editedIndex === -1 "
                                  class="red--text font-weight-bold"
                                > * </span>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.postNumber"
                            outlined
                            :label="$t('table.headers.postalCode')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.prefectures"
                            outlined
                            :label="$t('table.headers.prefecture')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.contactAddress"
                            outlined
                            :label="$t('table.headers.schoolLocation')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.contactNumber"
                            outlined
                            :label="$t('table.headers.phoneNumber')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.note"
                            outlined
                            :label="$t('table.headers.remarks')"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-row class="d-flex justify-end mb-3">
                      <v-col cols="3">
                        <v-btn
                          block
                          color="primary"
                          @click="close"
                        >
                          <span class="text-h5 font-weight-bold"> {{ $t('messages.cancel') }}</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          block
                          color="primary"
                          type="submit"
                          :disabled="!editedItem.name.trim()"
                          @click="save"
                        >
                          <span class="text-h5 font-weight-bold">{{ $t('messages.save') }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="font-weight-bold text-h4">
                    <v-row class="d-flex align-center justify-center">
                      <v-col cols="1">
                        <v-icon
                          large
                          color="blue darken-2"
                        >
                          mdi-delete-outline
                        </v-icon>
                      </v-col>
                      <v-col cols="8">
                        この操作は元に戻りません。
                        本当に削除してよろしいですか？
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />

                    <v-btn
                      class="text-h5"
                      dark
                      color="#E43E08"
                      @click="deleteItemConfirm"
                    >
                      {{ $t('messages.delete') }}
                    </v-btn>
                    <v-btn
                      class="text-h5"
                      @click="closeDelete"
                    >
                      {{ $t('messages.cancel') }}
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="2">
              <v-dialog
                v-model="dialogImportFile"
                max-width="687px"
                @click:outside="closeDialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="forth"
                    class="mb-5 pt-0 pl-10 pr-10 white--text d-flex justify-end"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="font-weight-bold text-h5">
                      {{ $t('messages.import_csv') }}</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ titleFormFile }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <div class="align-self-center mr-9">
                            <v-file-input
                              ref="fileupload"
                              v-model="file"
                              :loading="loading"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              counter
                              show-size
                              outlined
                              prepend-icon=""
                              @click:clear="clear"
                            >
                              <template v-slot:counter="props">
                                <div>
                                  {{
                                    props.props.value.match(/\d+\.*\d*/g)[0] +
                                      'ファイル （合計' +
                                      props.props.value.match(/\d+\.*\d*/g)[1] +
                                      'B）'
                                  }}
                                </div>
                              </template>
                            </v-file-input>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-row class="d-flex justify-end mb-3">
                      <v-col cols="3">
                        <v-btn
                          block
                          color="default"
                          @click="closeDialog"
                        >
                          <span class="text-h5 font-weight-bold"> {{ $t('messages.cancel') }}</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          block
                          color="primary"
                          type="submit"
                          :disabled="!file"
                          @click="onSubmit"
                        >
                          <span class="text-h5 font-weight-bold">{{ $t('messages.save') }} </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.index="props">
        {{ getIndex(props.index) }}
      </template>
      <template v-slot:item.holiday="props">
        <a
          style="color: #1BC5BD"
          :href="'/college-holiday/'+ props.item.id
          "
        >
          {{ props.item.holiday && getCollegeHolidayName(props.item.holiday) || '休暇情報を見る' }}
        </a>
      </template>
      <template v-slot:item.holidayPeriod="props">
        {{ props.item.holiday && getCollegeHolidayPeriod(props.item.holiday) || null }}
      </template>
      <template v-slot:footer.prepend />
      <template v-slot:item.actions="{ item }">
        <v-icon
          color="#5CA6D2"
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil-circle
        </v-icon>
        <v-icon
          dark
          color="#C5DCFA"
          @click="deleteItem(item)"
        >
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import XLSX from 'xlsx'
  import { get } from 'vuex-pathify'
  import moment from 'moment'

  export default {
    name: 'CollegesManager',
    data () {
      return {
        moment,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInterval: null,
        options: {},
        headers: [],
        editedIndex: -1,
        items: [],
        search: '',
        loading: false,
        jobOptions: [],
        filterSearch: '',
        collegeIsActive: null,
        dialog: false,
        dialogDelete: false,
        dialogImportFile: false,
        editedItem: {
          name: '',
          postNumber: '',
          prefectures: '',
          contactAddress: '',
          contactNumber: '',
          note: '',
        },
        defaultItem: {
          name: '',
          postNumber: '',
          prefectures: '',
          contactAddress: '',
          contactNumber: '',
          note: '',
        },
        rules: [
          value => !!value || this.$t('validator.required'),
        ],
        titleFormFile: this.$t('messages.importCSV'),
        file: [],
        excelFileConfig: {
          fileOffSet: 1,
          fileHeaderStart: 1,
          fileDataSkip: 1,
        },
      }
    },
    computed: {
      ...get('colleague', ['collegeList', 'status', 'message', 'error']),
      formTitle () {
        return this.editedIndex === -1 ? '新規追加' : '編集'
      },
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      collegeList (value) {
        this.$set(this, 'items', value)
        window.scrollTo(0, document.body.scrollHeight)
      },
      search (value) {
        this.initialize(value.trim())
      },
      status (value) {
        this.loading = value === 'loading'
      },
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    mounted () {
      if (this.$route.query) {
        if (this.$route.query.success) this.showSnackBar('messages.success')
      }
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'center',
          value: 'index',
          class: 'font-weight-bold',
          width: '5%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.college_name'),
          align: 'start',
          value: 'name',
          class: 'font-weight-bold',
          width: '14%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.postalCode'),
          align: 'start',
          value: 'postNumber',
          class: 'font-weight-bold',
          width: '9%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.prefecture'),
          align: 'start',
          value: 'prefectures',
          class: 'font-weight-bold',
          width: '9%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.schoolLocation'),
          align: 'start',
          value: 'contactAddress',
          class: 'font-weight-bold',
          sortable: false,
        },
        {
          text: this.$t('table.headers.phoneNumber'),
          align: 'start',
          value: 'contactNumber',
          class: 'font-weight-bold',
          width: '9%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.remarks'),
          align: 'start',
          value: 'note',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.holiday'),
          align: 'start',
          value: 'holiday',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('table.headers.holidayPeriod'),
          align: 'start',
          value: 'holidayPeriod',
          class: 'font-weight-bold',
          width: '10%',
          sortable: false,
        },
        {
          align: 'start',
          value: 'actions',
          width: '8%',
          sortable: false,
        },
      ]
      this.jobOptions = [
        { text: this.$t('messages.bulkAll'), value: 'ENTIRE' },
        { text: this.$t('messages.parttimeJobOnly'), value: 'ONLY_PARTTIME_JOB' },
      ]
    },
    methods: {
      initialize (search = '') {
        const tmpOptions = { ...this.options }
        if (this.search.trim()) {
          tmpOptions.keyword = this.search.trim()
          if (this.collegeIsActive) {
            tmpOptions.isActive = this.collegeIsActive
          }
        }
        if (this.collegeIsActive) {
          tmpOptions.isActive = this.collegeIsActive
        }
        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('colleague/getColleges', tmpOptions)
      },
      onSearch () {
        this.initialize(this.search)
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInterval) clearInterval(this.snackbarCloseInterval)
          this.snackbarCloseInterval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getCollegeHolidayName (item) {
        if (item) {
          return item.name
        }
      },
      getCollegeHolidayPeriod (item) {
        if (item) {
          return item.startAt + ' ~ ' + item.endAt
        }
      },
      selectEvent (value) {
        if (value === 'ENTIRE') {
          this.filterSearch = value
          this.collegeIsActive = null
        } else if (value === 'ONLY_PARTTIME_JOB') {
          this.filterSearch = value
          this.collegeIsActive = true
        }
        this.initialize()
      },
      editItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.$store.dispatch('colleague/deleteCollege', {
          id: this.editedIndex,
        })
        this.closeDelete()
        this.initialize()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          this.$store.dispatch('colleague/updateCollege', {
            id: this.editedIndex,
            options: {
              name: this.editedItem.name,
              postNumber: this.editedItem.postNumber,
              prefectures: this.editedItem.prefectures,
              contactNumber: this.editedItem.contactNumber,
              contactAddress: this.editedItem.contactAddress,
              note: this.editedItem.note,
            },
          })
          this.initialize()
        } else {
          this.$store.dispatch('colleague/createCollege', {
            options: {
              name: this.editedItem.name,
              postNumber: this.editedItem.postNumber,
              prefectures: this.editedItem.prefectures,
              contactNumber: this.editedItem.contactNumber,
              contactAddress: this.editedItem.contactAddress,
              note: this.editedItem.note,
            },
          })
          this.initialize()
        }
        this.close()
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      async onSubmit () {
        if (this.file) {
          var workbook = await this.readData(this.file)
          await this.$store.dispatch('colleague/importFile', { data: workbook })
          this.initialize()
          this.closeDialog()
        }
      },
      readData (fileName) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.onload = e => {
            const bstr = e.target.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            var data = XLSX.utils.sheet_to_json(ws, {
              header: ['id', 'name', 'postNumber', 'prefectures', 'contactAddress', 'contactNumber', 'note'],
              defval: '',
            })
            resolve(data.slice(2))
          }
          reader.onerror = function (ex) {
            reject(ex)
          }
          reader.readAsBinaryString(fileName)
        })
      },
      clear () {
        this.$refs.fileupload.reset()
      },
      closeDialog () {
        this.$refs.fileupload.reset()
        this.dialogImportFile = false
      },
    },
  }
</script>
<style lang="css">
.v-data-table {
  max-width: 100%;
}
</style>
